/*
 * Copyright 2024 LLC Campus.
 */

import { ToastContainer } from 'react-toastify'
import CatalogItem from './CatalogItem'
import React from 'react'

/**
 * Компонент списка промокодов/курсов/т.д.
 * @param items {Object[]} Список объектов для отображения
 * @param selectedItemId {String} Id выбранного элемента (для выделения карточки)
 * @param handleCardClick {Function} Функция обработки нажатия
 * */
const ItemGrid = ({items, selectedItemId, handleCardClick}) => {
  return (
    <div className="promocode-grid">
      <ToastContainer/>
      {items.length > 0 ? (
        items.map(item => (
          <CatalogItem
            key={item._id}
            item={item}
            isSelected={selectedItemId === item._id}
            onClick={() => handleCardClick(item._id)}
          />
        ))
      ) : (
        <p className="no-promocodes-message">Нет доступных для покупки тарифов</p>
      )}
    </div>
  )
}

export default ItemGrid
