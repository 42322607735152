/*
 * Copyright 2024 LLC Campus.
 */

import React, { useState } from 'react'
import '../styles/CourseAccessPage.css'
import CourseAccessEmailInputContainer from '../components/CourseAccessEmailInputContainer'
import { useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { grantCourseAccess } from '../services/api'

function CourseAccessPage () {
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [email, setEmail] = useState('')
  const { key } = useParams()

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
    setIsEmailValid(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(event.target.value))
  }

  const handleButtonClick = async () => {
    try {
      const data = await grantCourseAccess(key, email)
      toast.success(data.message)
      if (data.redirectUrl) {
        setTimeout(function () {
          window.location.replace(data.redirectUrl)
        }, 2000)
      }
    } catch (e) {
      toast.error(e.message)
    }
  }

  return (
    <div>
      <ToastContainer/>
      <div className="course-access">
        <div className="course-access-header">
          <div className="course-access-header-title">Получить доступ к курсам Кампус</div>
        </div>
        <CourseAccessEmailInputContainer
          email={email}
          isEmailValid={isEmailValid}
          handleEmailChange={handleEmailChange}
          handleButtonClick={handleButtonClick}
        />
      </div>
    </div>
  )
}

export default CourseAccessPage
