/*
 * Copyright 2024 LLC Campus.
 */

import React from 'react'
import '../styles/PromocodeCard.css'
import CreditCardIcon from './CreditCardIcon'

/**
 * Компонент карточки промокодов/курсов/т.д.
 * @param item {Object} Объект промокода/курса и т.д.
 * @param isSelected {boolean} Выбрана ли данная карточка
 * @param onClick {Function} Функция обработки нажатия
 * */
const CatalogItem = ({ item, isSelected, onClick }) => {
  return (
    <div className={`promocode-card ${isSelected ? 'selected' : ''}`} onClick={onClick}>
      <div className="promocode-card-content">
        <div className="promocode-card-header">
          <h3 className="promocode-name">{item.name}</h3>
          <p className="promocode-price">
            <span>{item.price.value} {item.price.currency}</span>
            <CreditCardIcon className="card-icon"/></p>
        </div>
      </div>
    </div>
  );
};

export default CatalogItem
