/*
 * Copyright 2024 LLC Campus.
 */

const CreditCardIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={18}
    fill="none"
    {...props}
  >
    <rect
      width={20}
      height={16}
      x={1}
      y={1}
      stroke="#ADB2B5"
      strokeWidth={2}
      rx={5}
    />
    <path
      stroke="#ADB2B5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M1 6.5h20M5 12.5h5"
    />
  </svg>
)

export default CreditCardIcon