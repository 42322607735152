/*
 * Copyright 2024 LLC Campus.
 */

export const AnalyticsEvents = {
  OPEN_PAGE: 'open_page',
  EMAIL_INPUT: 'email_input',
  ITEM_SELECTED: 'item_selected',
  PURCHASE_CLICK: 'purchase_click',
  PURCHASE_ERROR: 'purchase_error',
  FINISH_PURCHASE: 'finish_purchase',
}

export const AnalyticsPresets = {
  PROMOCODE_CATALOG: {screen_name: 'promocodeCatalog', prefix:"promocode_catalog_"},
  COURSE_CATALOG: {screen_name: 'courseCatalog', prefix:"course_catalog_"},
}

/**
 * Класс для отправки аналитики.
 */
class Analytics {
  #google_app_name = 'campusPromocodeWebsite'

  /**
   * @typedef {Object} EventMetadata
   * @property {String} screen_name
   * @property {String} prefix
   */

  /**
   *
   * @param eventName {String}
   * @param metadata {EventMetadata}
   */
  async sendEvent(eventName, metadata) {
    await this.sendGoogleEvent((metadata.prefix||'')+eventName, metadata)
  }

  async sendGoogleEvent(eventName, metadata){
    if (!metadata.screen_name) {
      console.error('Can\'t send Google event "'+eventName+'" without screen_name defined in metadata.')
      return;
    }
    let googleOpts = {app_name: this.#google_app_name}
    if(metadata.screen_name) googleOpts.screen_name = metadata.screen_name
    // eslint-disable-next-line no-undef
    await gtag('event', eventName, googleOpts)
  }
}
const analytics = new Analytics();

export default analytics