/*
 * Copyright 2024 LLC Campus.
 */

import React, { useEffect, useState } from 'react'
import { checkStatus, fetchConfirmationToken, fetchCourseCatalog, getCourseData } from '../services/api'
import { toast, ToastContainer } from 'react-toastify'
// import { openYooKassaWidget } from '../yookassa'
import { Link, useLocation } from 'react-router-dom'
// import ItemGrid from '../components/ItemGrid'
// import EmailInputContainer from '../components/EmailInputContainer'
// import analytics, { AnalyticsEvents, AnalyticsPresets } from '../analytics'
import { MoonLoader } from 'react-spinners'
// import { FaRegCopy } from 'react-icons/fa'

function CourseCatalog () {
  const location = useLocation()
  const courseId = new URLSearchParams(location.search).get('courseId')
  const [hasErrorToast, setHasErrorToast] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [courseUrl, setCourseUrl] = useState(null)

  useEffect(() => {
    let isMounted = true
    localStorage.clear()
    if (!courseId) {
      if (isMounted) {
        const errorMessage = 'Ошибка: courseId отсутствует. Страница не может быть загружена.'
        toast.error(errorMessage)
        setHasErrorToast(true)
      }
      return
    }

    const getCourseUrl = async () => {
      try {
        const courseData = await getCourseData(courseId)

        if (courseData.webUrl == null) throw new Error('Не найдена ссылка на курс')

        setCourseUrl(courseData.webUrl)
        setLoading(false)
        window.location.href = courseData.webUrl
      } catch (error) {
        toast.error(error.message)
        setError(error)
        setLoading(false)
      }
    }

    getCourseUrl()
  }, [courseId])

  return (
    <div className="overlay">
      <ToastContainer />
      {error && (
        <div className="error-container">
          <p>Произошла ошибка при загрузке.</p>
          <p>Причина: {error.message}</p>
        </div>
      )}

      {loading && !error && (
        <div className="loader-container">
          <MoonLoader
            size={50}
            color="#36D7B7"
            loading={loading}
          />
          <p className="loader-text">Идет загрузка...</p>
        </div>
      )}
    </div>
  )
}
// временно отключено CAMPUS-T-1791
// function CourseCatalog () {
//   const location = useLocation()
//   const courseId = new URLSearchParams(location.search).get('courseId')
//   const [tariffs, setCourseTariffs] = useState([])
//   const [email, setEmail] = useState('')
//   const [selectedTarifId, setSelectedTarifId] = useState(null)
//   const [isWidgetOpen, setIsWidgetOpen] = useState(false)
//   const [isEmailValid, setIsEmailValid] = useState(true)
//   const [hasErrorToast, setHasErrorToast] = useState(false)
//
//   const [emailInputStarted, setEmailInputStarted] = useState(false)
//   const [promocodeFirstSelected, setPromocodeFirstSelected] = useState(false)
//
//   useEffect(() => {
//     let isMounted = true
//     localStorage.clear()
//     if (!courseId) {
//       if (isMounted) {
//         const errorMessage = 'Ошибка: courseId отсутствует. Страница не может быть загружена.'
//         toast.error(errorMessage)
//         setHasErrorToast(true)
//       }
//       return
//     }
//
//     const fetchData = async () => {
//       try {
//         const data = await fetchCourseCatalog(courseId)
//         if (isMounted) {
//           setCourseTariffs(data.items)
//           analytics.sendEvent(AnalyticsEvents.OPEN_PAGE, {...AnalyticsPresets.COURSE_CATALOG})
//         }
//       } catch (error) {
//         if (isMounted) {
//           if (!hasErrorToast) {
//             toast.error(error.message)
//             setHasErrorToast(true)
//           }
//         }
//       }
//     }
//
//     fetchData()
//
//     return () => {
//       isMounted = false
//     }
//   }, [courseId, hasErrorToast])
//
//   const handleEmailChange = (event) => {
//     if(!emailInputStarted) {
//       analytics.sendEvent(AnalyticsEvents.EMAIL_INPUT, {...AnalyticsPresets.COURSE_CATALOG})
//       setEmailInputStarted(true)
//     }
//     setEmail(event.target.value)
//     setIsEmailValid(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(event.target.value))
//   }
//
//   const handleCardClick = (tarifId) => {
//     setSelectedTarifId(tarifId)
//     if(!promocodeFirstSelected) {
//       analytics.sendEvent(AnalyticsEvents.ITEM_SELECTED, {...AnalyticsPresets.COURSE_CATALOG})
//       setPromocodeFirstSelected(true)
//     }
//   }
//
//   const handlePurchaseClick = async () => {
//     const selectedTarif = tariffs.find(tarif => tarif._id === selectedTarifId)
//
//     if (selectedTarif) {
//       analytics.sendEvent(AnalyticsEvents.PURCHASE_CLICK, {...AnalyticsPresets.COURSE_CATALOG})
//       try {
//         const data = await fetchConfirmationToken(selectedTarifId, email)
//
//         if (data.confirmationToken && data.transactionId) {
//           localStorage.setItem('purchaseData', JSON.stringify({
//             returnPath: `/course?courseId=${courseId}`,
//             activationLink: `campus://courses/activate?courseId=${courseId}&code=`,
//             analyticsMeta: AnalyticsPresets.COURSE_CATALOG
//           }))
//           setIsWidgetOpen(true)
//           openYooKassaWidget(data.confirmationToken, selectedTarif, data.transactionId)
//         }
//       } catch (error) {
//         toast.error(error.message)
//         analytics.sendEvent(AnalyticsEvents.PURCHASE_ERROR, {...AnalyticsPresets.COURSE_CATALOG})
//       }
//     }
//   }
//
//   return (
//     <div>
//       <div id="yookassa-container" className={isWidgetOpen ? '' : 'hidden'}></div>
//       <div className={`promocode-catalog ${isWidgetOpen ? 'hidden' : ''}`}>
//         <div className="promocode-catalog-header">
//           <div className="promocode-catalog-header-title">Покупка доступа к курсу</div>
//         </div>
//         <ItemGrid
//           items={tariffs}
//           selectedItemId={selectedTarifId}
//           handleCardClick={handleCardClick}
//         />
//         <EmailInputContainer
//           email={email}
//           isEmailValid={isEmailValid}
//           selectedItemId={selectedTarifId}
//           handleEmailChange={handleEmailChange}
//           handlePurchaseClick={handlePurchaseClick}
//           infoBlock={
//             <>
//               <p>После оплаты:</p>
//               <ul>
//                 <li>Код активации доступа к Курсу придет к вам на указанный e-mail.</li>
//                 <li>Вы можете его подарить другу или активировать подписку для себя в самом приложении: Настройки -
//                   Кампус Premium.
//                 </li>
//               </ul>
//             </>
//           }
//           supportTextBlock={
//             <>
//               <p><strong>Возникли проблемы или есть вопросы?</strong></p>
//               <p>Если у вас возникли проблемы с оплатой или есть какие-либо вопросы, пожалуйста, свяжитесь с нами через
//                 один из доступных каналов:</p>
//               <ul>
//                 <li>E-mail: <a href="mailto:hello@campusapp.ru">hello@campusapp.ru</a></li>
//                 <li>Чат: в мобильном приложении</li>
//               </ul>
//               <p>Мы готовы помочь вам в любое время!</p>
//             </>
//           }
//         />
//       </div>
//     </div>
//   )
// }

export default CourseCatalog
