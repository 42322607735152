/*
 * Copyright 2024 LLC Campus.
 */

import React from 'react'
import { toast } from 'react-toastify'

const EmailInputContainer = ({
  email,
  handleEmailChange,
  isEmailValid,
  handlePurchaseClick,
  selectedItemId,
  infoBlock,
  supportTextBlock
}) => {

  const handleButtonClick = async () => {
    if(!selectedItemId){
      toast.error('Выберите тариф')
      return
    }
    if(!email){
      toast.error('Введите email')
      return
    }
    if(!isEmailValid){
      toast.error('Введите корректный email')
      return
    }
    await handlePurchaseClick()
  }

  return (
    <div className="email-input-container">
      <input
        type="email"
        placeholder="Введите email"
        value={email}
        onChange={handleEmailChange}
        className={`email-input ${!isEmailValid ? 'invalid' : ''}`}
      />
      {!isEmailValid && <div className="error-message">Введите корректный email</div>}

      <div className="promocode-info"> {infoBlock} </div>

      <button
        onClick={handleButtonClick}
        className={`purchase-button`}
      >
        Перейти к оплате
      </button>
      <div className="support-text"> {supportTextBlock} </div>
    </div>
  )
}

export default EmailInputContainer
